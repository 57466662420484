/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

fuse-vertical-navigation {
    @apply bg-primary-800 #{!important};
}

fuse-vertical-navigation-group-item {

    > .fuse-vertical-navigation-item-wrapper {

        .fuse-vertical-navigation-item {

            .fuse-vertical-navigation-item-title-wrapper {

                .fuse-vertical-navigation-item-title {
                    @apply text-accent-500 #{!important};
                }
            }
        }
    }
}
